import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import {
  isLoggedInSelector,
  authUserIdSelector,
  authRoleSelector,
  authRoleListSelector,
} from 'src/store/selectors/authSelector';
import {
  tenantBrandingLogoUrlSelector,
  tenantBrandingLogoAltSelector,
  tenantBrandingNameSelector,
} from 'src/store/selectors/tenantBrandingSelector';
import {
  isOneCompanyUserSelector,
  hasDefaultPolicySelector,
  selectedCompanyIdSelector,
  selectedCompanyNameSelector,
  isViewConfigurationCompanySelector,
} from 'src/store/selectors/companySelector';
import {
  selectedMembershipIdSelector,
  selectedMembershipIsViewSelector,
  selectedPensionRefSelector,
} from 'src/store/selectors/membershipSelector';

import { Navigation } from '@itm/shared-frontend/lib/components';
import { clientPortalDashboardUrl } from '@itm/shared-frontend/lib/utils';
import { MenuIcon, PensionFusionIcon, HideIcon } from '@itm/shared-frontend/lib/components/icons';
import type { NavigationLocalConfig } from '@itm/shared-frontend/lib/components/Navigation';

import CompanyFilter from 'src/components/CompanyFilter';

import { isAzureTargetPlatform } from 'src/utils/constants';

import { getPermissionsAssignedToUser } from 'src/api/clientPortal/permissions';

import { Role, GlobalRole, UserPermissionResponse } from 'src/types';

import styles from './Sidebar.module.scss';

type Props = Readonly<{
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}>;

function Sidebar({ isActive, setIsActive }: Props) {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userId = useSelector(authUserIdSelector);
  const userRole = useSelector(authRoleSelector);
  const userRoleListFromToken = useSelector(authRoleListSelector);
  const brandingLogoUrl = useSelector(tenantBrandingLogoUrlSelector);
  const brandingLogoAlt = useSelector(tenantBrandingLogoAltSelector);
  const brandingName = useSelector(tenantBrandingNameSelector);

  const isOneCompanyUser = useSelector(isOneCompanyUserSelector);
  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const selectedCompanyName = useSelector(selectedCompanyNameSelector);
  const isViewConfigurationCompany = useSelector(isViewConfigurationCompanySelector);
  const hasDefaultPolicy = useSelector(hasDefaultPolicySelector);

  const selectedMembershipId = useSelector(selectedMembershipIdSelector);
  const selectedPensionRef = useSelector(selectedPensionRefSelector);
  const selectedMembershipIsView = useSelector(selectedMembershipIsViewSelector);

  const [userPermissionList, setUserPermissionList] = useState<UserPermissionResponse[]>([]);

  const userGlobalRoleListByCompany = useMemo(
    () =>
      selectedCompanyId && userPermissionList.length
        ? userPermissionList.reduce<GlobalRole[]>((acc, { companyId, productRoleName }) => {
            if (selectedCompanyId === companyId && !acc.includes(productRoleName)) {
              acc.push(productRoleName);
            }
            return acc;
          }, [])
        : [],
    [selectedCompanyId, userPermissionList],
  );

  const userRoleList = useMemo<GlobalRole[]>(() => {
    if (!userRole) return [];
    if (userRole === Role.SuperAdmin || userRole === Role.Support) return [userRole] as unknown as GlobalRole[];
    const clientPortalRole = userRoleListFromToken.find((role) => role.startsWith('ClientPortal'));

    return clientPortalRole ? [clientPortalRole, ...userGlobalRoleListByCompany] : userGlobalRoleListByCompany;
  }, [userGlobalRoleListByCompany, userRole, userRoleListFromToken]);

  const fetchUserPermissionList = useCallback(async () => {
    if (!userId || userRole === Role.SuperAdmin || userRole === Role.Support) return;
    try {
      const res = await getPermissionsAssignedToUser(userId);
      setUserPermissionList(res.data);
    } catch {
      console.warn('User Permissions unavailable');
    }
  }, [userId, userRole]);

  const navigationLocalConfig = useMemo<NavigationLocalConfig<Role>>(
    () => [
      {
        name: 'All Products',
        section: 'products',
        path: clientPortalDashboardUrl,
        icon: () => <MenuIcon />,
      },
      {
        name: 'Pension Fusion',
        section: 'products',
        path: RoutePath.root,
        isInternalRoute: true,
        icon: () => <PensionFusionIcon />,
        children: [
          {
            name: 'Configuration',
            path: RoutePath.configurationRoot,
            role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin, Role.Manager] },
            children: [
              {
                name: isOneCompanyUser ? null : 'General Settings',
                path: RoutePath.configurationGeneralSettingsList,
                role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin] },
              },
              // Configuration Company
              {
                name: selectedCompanyName,
                path: isViewConfigurationCompany
                  ? RoutePath.configurationGeneralSettingsViewRoot
                  : RoutePath.configurationGeneralSettingsEditRoot,
                role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin] },
                children: [
                  {
                    name: 'PDP connection',
                    path: isViewConfigurationCompany
                      ? RoutePath.configurationGeneralSettingsViewPDPConnectionRoot
                      : RoutePath.configurationGeneralSettingsEditPDPConnectionRoot,
                    role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin, Role.Manager] },
                  },
                  {
                    name: 'Additional options',
                    path: isViewConfigurationCompany
                      ? RoutePath.configurationGeneralSettingsViewAdditionalOptions
                      : RoutePath.configurationGeneralSettingsEditAdditionalOptions,
                    role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst] },
                  },
                  {
                    name: 'Complex matching',
                    path: isViewConfigurationCompany
                      ? RoutePath.configurationGeneralSettingsViewComplexMatchingRoot
                      : RoutePath.configurationGeneralSettingsEditComplexMatchingRoot,
                    role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin, Role.Manager] },
                  },
                ],
              },
              // Configuration Matching
              {
                name: 'Matching',
                path: RoutePath.configurationMatchingRoot,
                role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin, Role.Manager] },
                children: [
                  {
                    name: 'All Policies',
                    path: RoutePath.configurationMatchingPolicyMain,
                    role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin, Role.Manager] },
                  },
                  {
                    name: hasDefaultPolicy ? null : 'Create New Policy',
                    path: RoutePath.configurationMatchingPolicyCreateRoot,
                    role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin, Role.Manager] },
                  },
                  {
                    name: 'Matching Test',
                    path: RoutePath.configurationMatchingTestRoot,
                    role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin, Role.Manager] },
                  },
                ],
              },
              // Configuration Publish
              {
                name: 'Publish Configuration',
                path: RoutePath.configurationPublishConfigurationRoot,
                role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin] },
                children: [
                  {
                    name: 'Publish Changes',
                    path: RoutePath.configurationPublishConfigurationPublishChanges,
                  },
                  {
                    name: 'Audit',
                    path: RoutePath.configurationPublishConfigurationAuditRoot,
                  },
                ],
              },
            ],
          },
          {
            name: 'Data Management',
            path: RoutePath.dataManagementRoot,
            role: { allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin, Role.Manager] },
            children: [
              {
                name: 'Data Upload History',
                path: RoutePath.dataManagementHistoryRoot,
              },
              {
                name: 'Upload Source Data',
                path: RoutePath.dataManagementUploadSourceData,
              },
              {
                name: 'Member Search',
                path: RoutePath.dataManagementMemberList,
              },
              {
                name: 'Audit',
                path: RoutePath.dataManagementAuditRoot,
              },
              {
                name: selectedMembershipIsView === null ? null : selectedPensionRef,
                path: selectedMembershipIsView
                  ? generatePath(RoutePath.dataManagementMemberViewRoot, { membershipId: selectedMembershipId })
                  : generatePath(RoutePath.dataManagementMemberEditRoot, { membershipId: selectedMembershipId }),
                children: [
                  {
                    name: 'Membership Detail',
                    path: selectedMembershipIsView
                      ? generatePath(RoutePath.dataManagementMemberViewDetailRoot, {
                          membershipId: selectedMembershipId,
                        })
                      : generatePath(RoutePath.dataManagementMemberEditDetailRoot, {
                          membershipId: selectedMembershipId,
                        }),
                  },
                  {
                    name: 'Pension Value Detail',
                    path: selectedMembershipIsView
                      ? generatePath(RoutePath.dataManagementMemberViewPensionValueRoot, {
                          membershipId: selectedMembershipId,
                        })
                      : generatePath(RoutePath.dataManagementMemberEditPensionValueRoot, {
                          membershipId: selectedMembershipId,
                        }),
                  },
                  {
                    name: 'Matching Details',
                    path: selectedMembershipIsView
                      ? generatePath(RoutePath.dataManagementMemberViewMatchingDetailsRoot, {
                          membershipId: selectedMembershipId,
                        })
                      : generatePath(RoutePath.dataManagementMemberEditMatchingDetailsRoot, {
                          membershipId: selectedMembershipId,
                        }),
                  },
                  {
                    name: 'Audit',
                    path: selectedMembershipIsView
                      ? generatePath(RoutePath.dataManagementMemberViewAuditRoot, {
                          membershipId: selectedMembershipId,
                        })
                      : generatePath(RoutePath.dataManagementMemberEditAuditRoot, {
                          membershipId: selectedMembershipId,
                        }),
                  },
                ],
              },
            ],
          },
          {
            name: 'Analytics & Reports',
            path: RoutePath.analyticsRoot,
            role: {
              allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin, Role.Manager, Role.Viewer],
            },
            children: [
              {
                name: 'Pension Fusion Insights',
                path: RoutePath.analyticsPensionFusionInsight,
              },
              {
                name: 'Possible Matches',
                path: RoutePath.analyticsPossibleMatches,
                role: {
                  allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin, Role.Manager],
                },
              },
              {
                name: 'Missing View Values',
                path: RoutePath.analyticsMissingViewValues,
                role: {
                  allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin, Role.Manager],
                },
              },
              {
                name: 'Member Extract',
                path: RoutePath.analyticsMemberExtract,
                role: {
                  allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin, Role.Manager],
                },
              },
              {
                name: 'Audit',
                path: RoutePath.analyticsFileExtractAudit,
                role: {
                  allowList: [Role.SuperAdmin, Role.Support, Role.Analyst, Role.ClientAdmin, Role.Manager],
                },
              },
            ],
          },
          {
            name: 'Product Information',
            path: RoutePath.productInfo,
            role: { allowList: [Role.SuperAdmin, Role.Support] },
          },
        ],
      },
    ],
    [
      isOneCompanyUser,
      selectedCompanyName,
      isViewConfigurationCompany,
      hasDefaultPolicy,
      selectedMembershipIsView,
      selectedPensionRef,
      selectedMembershipId,
    ],
  );

  useEffect(() => {
    fetchUserPermissionList();
  }, [fetchUserPermissionList]);

  return (
    <aside
      className={classNames([
        styles.Wrapper,
        'is-flex is-flex-direction-column has-background-white is-relative',
        { [styles.IsActive]: isActive },
      ])}
      id="sidebar"
    >
      <header className={classNames(styles.Header, 'is-flex-shrink-0')}>
        <div className="columns is-align-items-center is-gapless mb-0">
          <div className="column is-narrow">
            <Link className={classNames(styles.Logo, 'has-icon')} to={RoutePath.root}>
              <img src={brandingLogoUrl} alt={brandingLogoAlt} />
            </Link>
          </div>
          {isActive && (
            <div className="column">
              <h2 className="title is-nowrap is-4 ml-4">{brandingName}</h2>
            </div>
          )}
        </div>
        {isActive && <CompanyFilter />}
      </header>
      {isLoggedIn && (
        <>
          <button
            className={classNames(styles.ToggleButton, 'has-icon', { 'is-active': isActive })}
            type="button"
            onClick={() => setIsActive(!isActive)}
            aria-controls="sidebar"
            aria-label={isActive ? 'Collapse sidebar' : 'Expand sidebar'}
          >
            <span className="icon">
              <HideIcon />
            </span>
          </button>
          {userRole && (
            <Navigation
              isActiveSidebar={isActive}
              localConfig={navigationLocalConfig}
              userRoleList={userRoleList}
              isAzureTargetPlatform={isAzureTargetPlatform}
              isCommonItemsCollapse
            />
          )}
        </>
      )}
    </aside>
  );
}

export default Sidebar;
