import { ispApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { TanstackTableSearchParamsAdapter } from '@itm/shared-frontend/lib/utils';

import { SchemeResponse, SchemeConfigurationResponse, SchemeConfigurationResponsePagedResponse } from 'src/types';

export const getSchemeListByCompanyId = (companyId: string, config: AxiosRequestConfig = {}) =>
  ispApi.get<SchemeResponse[]>(`/isp-internal/api/v1/schemes/assignedToCompany/${companyId}`, config);

export const getCompanyConfigurationSchemeListByCompanyId = (companyId: string, config: AxiosRequestConfig = {}) =>
  ispApi.get<SchemeConfigurationResponse[]>(
    `/isp-internal/api/v1/schemes/companies/${companyId}/configurations`,
    config,
  );

export type CompanyConfigurationSchemeListSearchParams = {
  companyId: string;
} & TanstackTableSearchParamsAdapter;

export const getCompanyConfigurationSchemeListSearch = (
  params: CompanyConfigurationSchemeListSearchParams,
  config: AxiosRequestConfig = {},
) =>
  ispApi.get<SchemeConfigurationResponsePagedResponse>('/isp-internal/api/v1/schemes/configurations/search', {
    ...config,
    params,
  });

export const enableSchemePDPPublish = (schemeId: string) =>
  ispApi.patch<void>(`/isp-internal/api/v1/schemes/${schemeId}/pdp`);

export const disableSchemePDPPublish = (schemeId: string) =>
  ispApi.delete<void>(`/isp-internal/api/v1/schemes/${schemeId}/pdp`);
