import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { SelectOption } from 'src/types';

export const companySliceSelector = (state: RootState) => state.company;

export const allCompanyListSelector = createSelector(companySliceSelector, (state) => state.allCompanies);

export const hasDefaultPolicySelector = createSelector(companySliceSelector, (state) => state.hasDefaultPolicy);

export const allCompanyOptionsSelector = createSelector(allCompanyListSelector, (allCompanyList) =>
  allCompanyList
    .map<SelectOption>(({ name, id }) => ({ label: name || '', value: id }))
    .sort((a, b) => a.label.localeCompare(b.label)),
);

export const isOneCompanyUserSelector = createSelector(
  allCompanyListSelector,
  (allCompanyList) => allCompanyList.length === 1,
);

export const isLoadingCompanySelector = createSelector(companySliceSelector, (state) => state.isLoading);

export const selectedCompanyIdSelector = createSelector(
  companySliceSelector,
  allCompanyOptionsSelector,
  ({ selectedCompanyId }, companyOptions) =>
    companyOptions.length
      ? companyOptions.find((option) => option.value === selectedCompanyId)
        ? selectedCompanyId
        : companyOptions[0].value
      : selectedCompanyId,
);

export const selectedCompanyNameSelector = createSelector(
  selectedCompanyIdSelector,
  allCompanyOptionsSelector,
  (companyId, companyOptions) => {
    if (!companyId) return '';
    const selectedOption = companyOptions.find(({ value }) => value === companyId);
    return selectedOption ? selectedOption.label : '';
  },
);

export const isViewConfigurationCompanySelector = createSelector(
  companySliceSelector,
  (state) => state.isViewConfigurationCompany,
);
