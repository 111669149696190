import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';
import { getCompanyListAll } from 'src/api/isp/company';
import { CompanyResponse } from 'src/types';

type CompanyState = {
  isLoading: boolean;
  allCompanies: CompanyResponse[];
  selectedCompanyId: string;
  hasDefaultPolicy: boolean;
  isViewConfigurationCompany: boolean;
};

export const fetchCompaniesAllAction = createAsyncThunk('company/fetchCompanyListAll', async () => {
  const res = await getCompanyListAll();
  return res.data;
});

const initialState: CompanyState = {
  isLoading: false,
  allCompanies: [],
  selectedCompanyId: '',
  hasDefaultPolicy: false,
  isViewConfigurationCompany: false,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    selectCompany(state: CompanyState, action: PayloadAction<string>) {
      state.selectedCompanyId = action.payload;
    },
    selectIsViewConfigurationCompany(state: CompanyState, action: PayloadAction<boolean>) {
      state.isViewConfigurationCompany = action.payload;
    },
    setHasDefaultPolicy(state: CompanyState, action: PayloadAction<boolean>) {
      state.hasDefaultPolicy = action.payload;
    },
  },
  extraReducers: (builder) => {
    // SuperAdmin
    builder.addCase(fetchCompaniesAllAction.pending, (state: CompanyState) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCompaniesAllAction.fulfilled, (state: CompanyState, action) => {
      state.isLoading = false;
      state.allCompanies = action.payload;
    });
    builder.addCase(fetchCompaniesAllAction.rejected, (state: CompanyState) => {
      state.isLoading = false;
    });

    // Cleanup
    builder.addCase(resetStore, (state: CompanyState) => {
      Object.assign(state, initialState);
    });
  },
});

export const { selectCompany, selectIsViewConfigurationCompany, setHasDefaultPolicy } = companySlice.actions;

export default companySlice.reducer;
